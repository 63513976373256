<template>
  <div class="about main-wrapper">
        <img :src="imgList[0].imgUrl" />
        <img :src="imgList[1].imgUrl" />
        <div
            :style="{
                background: '#FAFAFA',
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                width: '100%',
            }">
            <div class="custom-title" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">{{ $store.getters.getLanguage === '' ? '三大研发中心' : 'Three major research and development centers'}}</div>
            <div v-if="$store.getters.getLanguage === ''">
                <div v-if="$store.getters.getDevice === 'web'">
                    <div class="custom-content">
                        严谨规范的研发流程和标准，依托大数据与人工智能技术平台，结合用户需求设计、
                    </div>
                    <div class="custom-content">
                        编写或修改微生物基因组，不断测评实验，研发创新技术，优化增强产品力
                    </div>
                </div>
                <div v-else>
                    <div class="custom-content">
                        严谨规范的研发流程和标准，依托大数据与人工智能技术平台，结合用户需求设计、编写或修改微生物基因组，不断测评实验，研发创新技术，优化增强产品力
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="$store.getters.getDevice === 'web'">
                    <div class="custom-content">Adhering to strict and standardized research and development processes and standards, relying on big data and artificial</div>
                    <div class="custom-content">intelligence technology platforms, combining user needs to design, write or modify microbial genomes, constantly</div>
                    <div class="custom-content">evaluating experiments, developing innovative technology, and enhancing product power.</div>
                </div>
                <div v-else>
                    <div class="custom-content" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                        Adhering to strict and standardized research and development processes and standards, relying on big data and artificial intelligence technology platforms, combining user needs to design, write or modify microbial genomes, constantly evaluating experiments, developing innovative technology, and enhancing product power.
                    </div>
                </div>
            </div>
            <custom-slider :device="$store.getters.getDevice">
                <template slot="content">
                    <img :src="slider[0].imgUrl" :style="{ 'margin-left': '18.75vw' }"/>
                    <img :src="slider[1].imgUrl" />
                    <img :src="slider[2].imgUrl" :style="{ 'margin-right': '0px' }"/>
                </template>
            </custom-slider>
        </div>
        <img :src="imgList[2].imgUrl" />
        <img :src="imgList[3].imgUrl" />
        <img :src="imgList[4].imgUrl" />
        <img :src="imgList[5].imgUrl" />
        <div v-if="$store.getters.getDevice === 'web'" :style="{'text-align': 'center'}">
            <img :src="imgList[6].imgUrl" class="timeline-title"/>
            <div class="timeline-wrapper" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                <img
                    class="timeline"
                    :src="imgList[7].imgUrl"
                    :style="{ top: '-' + timelineVal + '%' }"
                    @dragstart="onTimelineDragStart"
                    @drag="onTimelineDrag"
                    @dragend="onTimelineDragEnd"
                />
                <div class="mask-top"></div>
                <div class="mask-bottom"></div>
                <input
                    type="range"
                    class="timeline-slider"
                    min="0"
                    max="150"
                    v-model="timelineVal"
                />
            </div>
        </div>
        <div v-else class="timeline-wrapper-mobile">
            <img :src="imgList[6].imgUrl" class="timeline-title"/>
            <img :src="imgList[7].imgUrl" class="timeline"/>
        </div>
  </div>
</template>

<script>
import CustomSlider from '../components/customSlider.vue';
import { getCosUrl } from "../utils";
export default {
  name: "about",
  components: { CustomSlider },
  data() {
    return {
        sliderVal: 0,
        sliderDragStartX: null,

        timelineVal: 0,
        timelineDragStartY: null,

        imgList: [
            { imgKey: "/about/1.webp", imgUrl: "" },
            { imgKey: "/about/2.webp", imgUrl: "" },
            { imgKey: "/about/3.webp", imgUrl: "" },
            { imgKey: "/about/4.webp", imgUrl: "" },
            { imgKey: "/about/5.webp", imgUrl: "" },
            { imgKey: "/about/6.webp", imgUrl: "" },
            { imgKey: "/about/timeline-title.webp", imgUrl: "" },
            { imgKey: "/about/timeline.webp", imgUrl: "" },
        ],
        slider: [
            { imgKey: "/about/slider/1.webp", imgUrl: "" },
            { imgKey: "/about/slider/2.webp", imgUrl: "" },
            { imgKey: "/about/slider/3.webp", imgUrl: "" },
        ]
    };
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
                this.initSlider();
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
                this.initSlider();
            }
        },
        deep: true
    }
  },
  mounted(){
    this.initImage();
    this.initSlider();
  },
  methods: {
    initImage(){
        const imgList = getCosUrl(this.imgList.map(item => { return item.imgKey }));
        this.imgList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    initSlider(){
        const imgList = getCosUrl(this.slider.map(item => { return item.imgKey }));
        this.slider.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    onSliderDragStart(e) {
      this.sliderDragStartX = e.clientX;
    },
    onSliderDrag(e) {
      let step = (e.clientX - this.sliderDragStartX) / 500;
      let cur = this.sliderVal - step;
      if (step < 0) {
        this.sliderVal = cur > 136 ? 136 : cur;
      } else {
        this.sliderVal = cur < 0 ? 0 : cur;
      }
    },
    onSliderDragEnd() {
      this.sliderDragStartX = null;
    },
    onTimelineDragStart(e) {
      this.timelineDragStartY = e.clientY;
    },
    onTimelineDrag(e) {
      let step = (e.clientY - this.timelineDragStartY) / 400;
      let cur = this.timelineVal - step;
      if (step < 0) {
        this.timelineVal = cur > 150 ? 150 : cur;
      } else {
        this.timelineVal = cur < 0 ? 0 : cur;
      }
    },
    onTimelineDragEnd() {
      this.timelineDragStartY = null;
    },
  },
};
</script>

<style lang="less">
.about {
    flex-direction: column;
    display: flex;
    align-items: center;
    .custom-title{
        @media screen and (max-device-width: 1199px) {
            margin: 13.3333vw auto 8vw;
            font-size: 6.4vw;
            &.EN{
                margin: 10.6667vw auto 3.2vw;
                font-size: 4.8vw;
                width: 58.6667vw;
            }
        }
    }
    .custom-content{
        @media screen and (max-device-width: 1199px) {
            width: 80.8vw!important;
            margin-bottom: 6.4vw;
            text-align: justify;
            font-size: 4.2667vw;
            &.EN{
                margin-bottom: 1.6vw;
                text-align: left;
                font-size: 3.2vw;
            }
        }
    }
    .custom-slider {
        // margin-left: 18.75vw;
        // padding: 0 18.75vw;
        margin-top: 6.6rem;
        overflow: hidden;
        width: 100%;
        @media screen and (max-device-width: 1199px) {
            margin-top: 0;
            .el-slider{
                margin: 5.8333vw auto 5.3333vw!important;
            }
            .el-slider__runway{
                margin: 0;
            }
        }
        // width: 120rem;
        .content-wrapper {
            align-items: center;
            position: relative;
            display: flex;
            @media screen and (min-device-width: 1200px) {
                img {
                    width: 108.5rem !important;
                    margin-right: 1.6vw;
                }
                img:last-child {
                    margin-right: 0;
                }
                
            }
            @media screen and (max-device-width: 1199px) {
                img{
                    width: 70.4vw!important;
                    margin-right: 3.2vw;
                }
            }
        }

    }
    .slider-wrapper {
        margin: 0 auto;
        margin-bottom: 5.4rem;
        margin-top: 8.4rem;
        display: flex;
        .slider {
            -webkit-appearance: none;
            width: 9.6rem;
            &::-webkit-slider-thumb {
                border: 1px solid rgba(0, 0, 0, 0.8);
                -webkit-appearance: none;
                border-radius: 117px;
                background: #fff;
                margin-top: -0.4rem;
                cursor: pointer;
                height: 0.9rem;
                width: 1.8rem;
            }
            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                cursor: pointer;
                height: 0.1rem;
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
    .timeline-title{
        margin: 12rem auto 8.4rem;
        height: 5.2rem;
        width: auto;
    }
    .timeline-wrapper {
        // margin-left: 34.375vw;
        margin-bottom: 4.4rem;
        position: relative;
        text-align: center;
        overflow: hidden;
        height: 52.8rem;
        width: 80rem;
        .timeline {
        width: 31.25vw !important;
        position: relative;
        object-fit: cover;
        height: auto;
        }
        &.EN{
            width: 110rem;
            .timeline{
                width: 55.2083vw!important;
            }
        }
        .mask-top {
        background: linear-gradient(
            rgba(255, 255, 255, 0.9) 70%,
            rgba(255, 255, 255, 0)
        );
        position: relative;
        top: -63.3vw;
        height: 8vw;
        width: 100%;
        left: 0;
        }
        .mask-bottom {
        width: 100%;
        height: 9vw;
        background: linear-gradient(
            to top,
            rgba(255, 255, 255, 0.9) 70%,
            rgba(255, 255, 255, 0)
        );
        position: absolute;
        left: 0;
        bottom: 0;
        }
        .timeline-slider {
        -webkit-transform: rotate(90deg);
        -webkit-appearance: none;
        position: absolute;
        width: 9.6rem;
        right: 0rem;
        top: 28rem;
        &::-webkit-slider-thumb {
            border: 1px solid rgba(0, 0, 0, 0.8);
            -webkit-appearance: none;
            background: #fff;
            border-radius: 117px;
            margin-top: -0.4rem;
            cursor: pointer;
            height: 0.9rem;
            width: 1.8rem;
        }
        &::-webkit-slider-runnable-track {
            background: rgba(0, 0, 0, 0.2);
            -webkit-appearance: none;
            cursor: pointer;
            height: 0.1rem;
        }
        }
    }
    .timeline-wrapper-mobile{
        flex-direction: column;
        align-items: center;
        display: flex;
        .timeline-title{
            width: 71.2vw!important;
            margin: 10.6667vw auto 8vw;
            height: auto;
        }
        .timeline{
            width: 77.0667vw!important;
            margin-bottom: 13.3333vw;
            height: auto;
        }
    }
}
</style>
